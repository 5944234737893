import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export const setWebhookEndpoint = async (idToken, endpoint) => {
  try {
    const data = await axios({
      method: "post",
      url: `${expressHost}/apiAdmin/setWebhookEndpoint`,
      headers: { idtoken: idToken },
      data: {
        endpoint: endpoint,
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
