import axios from "axios";
import { expressHost } from "../../../utils/hosts";
import { method } from "lodash";

export const saveColumnSelection = async ({ idToken, columns }) => {
  try {
    const data = await axios({
      method: "POST",
      url: `${expressHost}/table/setTableColumns`,
      data: {
        columns: columns,
      },
      headers: { idtoken: idToken },
    });
    return data;
  } catch (error) {
    console.log("what is error of save columns", error);
    throw error;
  }
};

export const fetchColumnSelection = async ({ idToken }) => {
  try {
    const columns = await axios({
      method: "GET",
      url: `${expressHost}/table/getTableColumns`,
      headers: { idtoken: idToken },
    });
    return columns.data.data;
  } catch (error) {
    console.log("error fetching columns for user", error);
    throw error;
  }
};
