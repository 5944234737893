export function createAccountsArray(data) {
  if (!data) {
    return [];
  }
  var accountsArr = [];
  for (let account in data) {
    for (let serviceClass in data[account]) {
      accountsArr.push([account, serviceClass]);
    }
  }
  return accountsArr;
}

export function checkBillsCount(data) {
  if (!data) {
    return false;
  }
  var max = 0;

  for (let account in data) {
    for (let serviceClass in data[account]) {
      max = Math.max(max, data[account][serviceClass].data.length);
    }
  }

  return max > 1;
}

export function getServiceClassArray(data) {
  var arrOfServiceClasses = [];
  for (let account in data) {
    for (let serviceClass in data[account]) {
      arrOfServiceClasses.push(serviceClass);
    }
  }
  var serviceClassArrSet = new Set(arrOfServiceClasses);
  return Array.from(serviceClassArrSet);
}

export function getServiceClassData(data, serviceClass) {
  var serviceClassData = [];
  for (let account in data) {
    for (let currentServiceClass in data[account]) {
      if (serviceClass === currentServiceClass) {
        serviceClassData = [
          ...serviceClassData,
          ...data[account][currentServiceClass].data,
        ];
      }
    }
  }
  return serviceClassData;
}

export function getServiceClassUnit(data, serviceClass) {
  function mostFrequentValue(arr) {
    // Create an object to store the frequency of each element
    const frequencyMap = {};

    // Populate the frequency map
    arr.forEach((value) => {
      if (frequencyMap[value]) {
        frequencyMap[value]++;
      } else {
        frequencyMap[value] = 1;
      }
    });

    // Find the most frequent value
    let maxFrequency = 0;
    let mostFrequent = null;

    for (const value in frequencyMap) {
      if (frequencyMap[value] > maxFrequency) {
        maxFrequency = frequencyMap[value];
        mostFrequent = value;
      }
    }

    return mostFrequent;
  }

  var units = [];
  for (let account in data) {
    for (let currentServiceClass in data[account]) {
      if (serviceClass === currentServiceClass) {
        units.push(data[account][currentServiceClass].overview.unit);
      }
    }
  }

  return mostFrequentValue(units);
}

export function calculateSumTotal(array, key) {
  return array.reduce((sum, obj) => {
    return sum + (obj[key] || 0);
  }, 0);
}

export function getMaxByKey(array, key) {
  return array.reduce((max, obj) => {
    return obj[key] > max ? obj[key] : max;
  }, -Infinity);
}

export function averageByKey(array, key) {
  const total = array.reduce((sum, obj) => {
    return sum + (obj[key] || 0);
  }, 0);

  const count = array.reduce((cnt, obj) => {
    return cnt + (obj.hasOwnProperty(key) ? 1 : 0);
  }, 0);

  return count === 0 ? 0 : total / count;
}

export function checkKey(array, key) {
  return array.some((obj) => obj.hasOwnProperty(key));
}

export function hasNonNullOrZeroKey(array, key) {
  return array.some((obj) => obj[key] !== null && obj[key] !== 0);
}

function hasAllKeys(
  obj,
  keys = [
    "energySupplyCharges",
    "supplySalesTax",
    "supplyOtherTax",
    "energyDeliveryCharges",
    "deliverySalesTax",
    "deliveryOtherTax",
  ]
) {
  // Check if all keys are in the object
  return keys.every((key) => key in obj);
}

function parseDate(dateString) {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day);
}

function daysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

function handleUndefined(s) {
  return s ? s : 0;
}

function getDaysInEachMonth(startDate, endDate) {
  const start = parseDate(startDate);
  const end = parseDate(endDate);
  const daysInMonthCount = Array(12).fill(0); // Array to hold the count of days in each month

  let currentDate = new Date(start);

  while (currentDate <= end) {
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const daysInCurrentMonth = daysInMonth(currentYear, currentMonth + 1);
    const startDay = currentDate.getDate();
    const endDay =
      currentYear === end.getFullYear() && currentMonth === end.getMonth()
        ? end.getDate()
        : daysInCurrentMonth;

    daysInMonthCount[currentMonth] += endDay - startDay + 1;

    // Move to the next month
    currentDate = new Date(currentYear, currentMonth + 1, 1);
  }

  // Create an object with month keys and days as values, excluding months with 0 days
  const result = {};
  for (let i = 0; i < 12; i++) {
    if (daysInMonthCount[i] > 0) {
      result[i] = daysInMonthCount[i];
    }
  }

  return result;
}

export function calculateAverageBlendedRate(
  arr,
  solarProductivity,
  weighted = false
) {
  let totalUsage = 0;
  let totalWeightedCharges = 0;

  arr.forEach((item) => {
    if (item.serviceClass && item.serviceClass !== "electric") return;
    if (
      item.usage &&
      Object.keys(item).some((key) =>
        ["supplyTaxes", "deliveryTaxes"].includes(key)
      )
    ) {
      let primaryEnergyCharges =
        (handleUndefined(item.energySupplyCharges) *
          (1 + handleUndefined(item.supplyTaxes)) +
          handleUndefined(item.energyDeliveryCharges) *
            (1 + handleUndefined(item.deliveryTaxes)) +
          handleUndefined(item.additionalEnergyCharges)) *
        (1 + handleUndefined(item.additionalTaxes));
      if (weighted ** item.startDate && item.endDate) {
        var daysInEachMonth = getDaysInEachMonth(item.startDate, item.endDate);
        for (let month in daysInEachMonth) {
          totalUsage +=
            item.usage * daysInEachMonth[month] * solarProductivity[month];
          totalWeightedCharges +=
            primaryEnergyCharges *
            daysInEachMonth[month] *
            solarProductivity[month];
        }
      } else {
        totalUsage += item.usage;
        totalWeightedCharges += primaryEnergyCharges;
      }
    } else if (
      item.usage &&
      (item.energySupplyCharges || item.energyDeliveryCharges)
    ) {
      if (weighted && item.startDate && item.endDate) {
        var daysInEachMonth = getDaysInEachMonth(item.startDate, item.endDate);
        for (let month in daysInEachMonth) {
          totalUsage +=
            item.usage * daysInEachMonth[month] * solarProductivity[month];
          totalWeightedCharges +=
            (handleUndefined(item.energySupplyCharges) *
              (1 + handleUndefined(item.supplyOtherTax)) *
              (1 + handleUndefined(item.supplySalesTax)) +
              handleUndefined(item.energyDeliveryCharges) *
                (1 + handleUndefined(item.deliveryOtherTax)) *
                (1 + handleUndefined(item.deliverySalesTax))) *
            daysInEachMonth[month] *
            solarProductivity[month];
        }
      } else {
        totalUsage += item.usage;
        totalWeightedCharges +=
          handleUndefined(item.energySupplyCharges) *
            (1 + handleUndefined(item.supplyOtherTax)) *
            (1 + handleUndefined(item.supplySalesTax)) +
          handleUndefined(item.energyDeliveryCharges) *
            (1 + handleUndefined(item.deliveryOtherTax)) *
            (1 + handleUndefined(item.deliverySalesTax));
      }
    }
  });

  if (totalUsage === 0) return "0 $/kWh"; // Avoid division by zero
  return String((totalWeightedCharges / totalUsage).toFixed(4)) + " $/kWh";
}

export const formatUsageChartData = (obj) => {
  var tempArr = [];
  for (let i = 0; i < obj.length; i++) {
    var timeObj = {
      x: new Date(obj[i].startDate).getTime(),
      y: obj[i].usage,
    };
    tempArr.push(timeObj);
  }
  return tempArr;
};

export function formatDataForChart(data, serviceClass) {
  var cleanData = [];
  for (let ac in data) {
    for (let sc in data[ac]) {
      if (sc === serviceClass) {
        cleanData.push({
          name: ac,
          data: formatUsageChartData(data[ac][sc].data).sort(
            (a, b) => a.x - b.x
          ),
        });
      }
    }
  }
  return cleanData;
}

export function checkForElectric(arr) {
  for (let e of arr) {
    if (e.serviceClass === "electric") return true;
  }

  return false;
}

function checkContinuousYear(data) {
  // Parse dates and sort the array by startDate
  const parsedData = data
    .map((item) => ({
      ...item,
      startDate: new Date(item.startDate),
      endDate: new Date(item.endDate),
    }))
    .sort((a, b) => a.startDate - b.startDate);

  let startOfYear = null;
  let currentDate = null;
  let totalUsage = 0;
  let totalCharges = 0;

  for (let i = 0; i < parsedData.length; i++) {
    const { startDate, endDate, usage, totalCharges: charges } = parsedData[i];

    if (!startOfYear) {
      // Set the start of year as the start date of the first object
      startOfYear = new Date(startDate);
      currentDate = new Date(endDate);
      totalUsage += usage;
      totalCharges += charges;
    } else {
      // Check if the next range is continuous from the last end date
      if (currentDate - startDate <= 24 * 60 * 60 * 1000) {
        currentDate = new Date(endDate);
        totalUsage += usage;
        totalCharges += charges;
      } else {
        // There is a gap, reset and start over
        startOfYear = null;
        totalUsage = 0;
        totalCharges = 0;
      }
    }

    // Check if we've reached a full year
    if (startOfYear && currentDate - startOfYear >= 365 * 24 * 60 * 60 * 1000) {
      return { totalUsage, totalCharges, estimate: false };
    }
  }

  // Handle the case where there is no continuous year
  return calculateEstimate(parsedData);
}

function calculateEstimate(data) {
  // Take the most recent object
  const mostRecent = data[data.length - 1];
  const oneYearAgo = new Date(mostRecent.startDate);
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

  let usage = 0;
  let charges = 0;
  let totalDays = 0;

  // Include all objects within 1 year of the most recent object
  data.forEach((item) => {
    const startDate = new Date(item.startDate);
    const endDate = new Date(item.endDate);

    if (endDate >= oneYearAgo) {
      const daysInRange = (endDate - startDate) / (1000 * 60 * 60 * 24);
      usage += item.usage;
      charges += item.totalCharges;
      totalDays += daysInRange;
    }
  });

  // Calculate average usage and charges per day
  const averageUsagePerDay = usage / totalDays;
  const averageChargesPerDay = charges / totalDays;

  // Estimate total usage and total charges for 365 days
  const totalUsage = averageUsagePerDay * 365;
  const totalCharges = averageChargesPerDay * 365;

  return { totalUsage, totalCharges, estimate: true };
}

export function getAnnualUsageAndCharges(data, serviceClass) {
  var usage = 0;
  var cost = 0;
  var estimate = false;

  for (let an in data) {
    for (let sc in data[an]) {
      if (sc === serviceClass) {
        const continuousYearCheckResults = checkContinuousYear(
          data[an][sc].data
        );
        usage += continuousYearCheckResults.totalUsage;
        cost += continuousYearCheckResults.totalCharges;
        estimate = continuousYearCheckResults.estimate === true;
      }
    }
  }

  return { usage, cost, estimate };
}
