import axios from "axios";
import { expressHost } from "../../../utils/hosts";

export default async function getUsageData(currentUser) {
  try {
    const idtoken = await currentUser.getIdToken();

    // Make a POST request to the endpoint
    const res = await axios(`${expressHost}/auth/getUsageData`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        idtoken: idtoken,
      },
    });

    return res.data.data;
  } catch (error) {
    console.error("Error during checkout:", error);
    throw error;
  }
}
