import axios from "axios";
import { expressHost } from "../../../../../utils/hosts";

export const pdfBillReader = async ({ files, idToken, customer, detailed }) => {
  try {
    // Extract filenames from the files array
    const filenames = Object.values(files).map((file) => file.name);

    // Request signed URLs from the server
    const response = await axios.post(
      `${expressHost}/pdfBills/getSignedUrls`,
      { filenames, customer, detailed },
      {
        headers: {
          "Content-Type": "application/json",
          idToken: idToken,
        },
      }
    );

    const signedUrls = response.data.data.signedUrls;

    // Upload each file to its corresponding signed URL
    const uploadPromises = Object.entries(signedUrls).map(([filename, url]) => {
      const file = Object.values(files).find((f) => f.name === filename);
      return axios.put(url, file, {
        headers: {
          "Content-Type": file.type,
        },
      });
    });

    // Wait for all uploads to complete
    await Promise.all(uploadPromises);
  } catch (error) {
    console.error("Error uploading files:", error);
    throw error; // Rethrow the error for further handling if needed
  }
};

export const getPdfFromImages = async ({ files, idToken }) => {
  try {
    const images = await Promise.all(
      Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result); // Base64 data URL
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      })
    );

    const response = await axios.post(
      `${expressHost}/pdfBills/imagesToPDF`,
      { images },
      {
        headers: {
          "Content-Type": "application/json",
          idToken: idToken,
        },
        responseType: "blob",
      }
    );
    return response;
  } catch (error) {
    console.error("images to pdf error:", error);
    throw error;
  }
};

export const testPDF = async ({ idToken }) => {
  try {
    const response = await axios.get(
      `${expressHost}/pdfBills/generate-test-pdf`,
      {
        headers: {
          "Content-Type": "application/json",
          idToken: idToken,
        },
      }
    );
    return response;
  } catch (error) {
    console.error("what is error", error);
  }
};
