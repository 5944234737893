import React, { useState, useEffect } from "react";

const ErrorBoundary = ({ children }) => {
  const [error, setError] = useState(null);

  const handleError = (error, errorInfo) => {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
    setError(error);
  };

  // Optional: Reset error on children change
  useEffect(() => {
    setError(null);
  }, [children]);

  if (error) {
    return (
      <div>
        <h1>Something went wrong.</h1>
        <p>{error.message}</p>
        <button onClick={() => setError(null)}>Try Again</button>
      </div>
    );
  }

  // Use a try-catch pattern to catch errors in child components
  try {
    return children;
  } catch (caughtError) {
    handleError(caughtError, null);
    return null;
  }
};

export default ErrorBoundary;
