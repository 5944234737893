import { expressHost } from "../../../utils/hosts";
import axios from "axios";

// const getInvoices = async (currentUser) => {
//   try {
//     const idtoken = await currentUser.getIdToken();

//     // Make a POST request to the endpoint
//     const res = await axios(`${expressHost}/payments/getInvoices`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//         idtoken: idtoken,
//       },
//     });
//     console.log(res.data.invoices);
//   } catch (error) {
//     console.error("Error during checkout:", error);
//   }
// };

export const resetPassword = async (currentUser) => {
  try {
    const idtoken = await currentUser.getIdToken();

    // Make a POST request to the endpoint
    const res = await axios(`${expressHost}/auth/resetPassword`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        idtoken: idtoken,
      },
    });
    console.log(res);
    return true;
  } catch (error) {
    console.error("Error during checkout:", error);
    throw error;
  }
};
