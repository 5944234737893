import React from "react";
import styled from "styled-components";
import greenCheck from "../../assets/greenCheck.svg";
import hazard from "../../assets/Hazard.svg";
import redX from "../../assets/redX.svg";
import info from "../../assets/blueInfo.svg";
import {
  dark_orange,
  light_orange,
  blue,
  light_blue,
} from "../../utils/colors";

export const snackbarStyles = {
  root: {
    flexGrow: 1,
    display: "flex",
    margin: 16,
    justifyContent: "center",
    alignItems: "middle",
  },
  button: {
    margin: 8,
    bordercolor: "#313131",
    color: "#313131",
  },
  success: {
    border: "1px solid #34C302",
    color: "#34C302",
    backgroundColor: "#F3FFF6",
  },
  error: {
    border: "1px solid #FA0E0E",
    color: "#FA0E0E",
    backgroundColor: "#FFF3F3",
  },
  info: {
    border: `1px solid ${blue}`,
    color: blue,
    backgroundColor: light_blue,
  },
  warning: {
    border: `1px solid ${dark_orange}`,
    color: dark_orange,
    backgroundColor: light_orange,
  },
};

// export const buttons = [
//   { variant: "success", message: "Successfully done the operation." },
//   { variant: "error", message: "Something went wrong." },
//   { variant: "warning", message: "Something could go wrong" },
//   { variant: "info", message: "For your info..." },
// ];

const Flex = styled.div`
  display: flex;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

export const buttons = {
  success: (
    <>
      <Flex>
        <Icon src={greenCheck} />
        <>Success</>
      </Flex>
    </>
  ),
  successMakingAdmin: (
    <>
      <Flex>
        <Icon src={greenCheck} />
        <>Successfully made user an admin</>
      </Flex>
    </>
  ),
  successDeletingUser: (
    <>
      <Flex>
        <Icon src={greenCheck} />
        <>Successfully deleted the user</>
      </Flex>
    </>
  ),
  successRemovingAdmin: (
    <>
      <Flex>
        <Icon src={greenCheck} />
        <>Successfully removed user as an admin</>
      </Flex>
    </>
  ),
  successResettingPassword: (
    <>
      <Flex>
        <Icon src={greenCheck} />
        <>Successfully reset your password</>
      </Flex>
    </>
  ),
  successGettingUsageData: (
    <>
      <Flex>
        <Icon src={greenCheck} />
        <>Successfully retrieved the usage data</>
      </Flex>
    </>
  ),
  successDeletingAPIKey: (
    <>
      <Flex>
        <Icon src={greenCheck} />
        <>Successfully deleted API key</>
      </Flex>
    </>
  ),
  error: (
    <>
      <Flex>
        <Icon src={redX} />
        <>Something Went Wrong</>
      </Flex>
    </>
  ),
  warning: (
    <>
      <Flex>
        <Icon src={hazard} />
        <>This May Cause an Error</>
      </Flex>
    </>
  ),
  info: (
    <>
      <Flex>
        <Icon src={info} />
        <>For Your Information</>
      </Flex>
    </>
  ),
  errorDuplicateUserForSeat: (
    <>
      <Flex>
        <Icon src={redX} />
        <>
          Something went wrong. An Account for this user already exists. User
          must delete account to be added as a seat.
        </>
      </Flex>
    </>
  ),
  errorMakingAdmin: (
    <>
      <Flex>
        <Icon src={redX} />
        <>
          Something went wrong. Unable to make that user an admin. Please try
          again or contact support.
        </>
      </Flex>
    </>
  ),
  errorDeletingUser: (
    <>
      <Flex>
        <Icon src={redX} />
        <>
          Something went wrong. Unable to delete the user. Please try again or
          contact support.
        </>
      </Flex>
    </>
  ),
  errorRemovingAdmin: (
    <>
      <Flex>
        <Icon src={redX} />
        <>
          Something went wrong. Unable to remove that user as an admin. Please
          try again or contact support.
        </>
      </Flex>
    </>
  ),
  errorResettingPassword: (
    <>
      <Flex>
        <Icon src={redX} />
        <>
          Something went wrong. Unable to reset your password. Please try again
          or contact support.
        </>
      </Flex>
    </>
  ),
  errorGettingUsageData: (
    <>
      <Flex>
        <Icon src={redX} />
        <>
          Something went wrong. Unable to get the usage data. Please try again
          or contact support.
        </>
      </Flex>
    </>
  ),
  errorDeletingAPIKey: (
    <>
      <Flex>
        <Icon src={redX} />
        <>
          Something went wrong. Unable to delete the API key. Please try again
          or contact support.
        </>
      </Flex>
    </>
  ),
};

// const MessageBar = ({type}) => {
//     const { enqueueSnackbar, closeSnackbar } = useSnackbar();
//     return (

//     )
// }

// export default MessageBar;
